import { createContext } from 'react'
export const ThemeContext = createContext({})
// export const ThinkContext = createContext({})

export const CHAT_DEFAULT = 'CHAT_DEFAULT'
export const defaultData = {
  creatKeyWordsExtra: '',
  creatMajorExtra: '',
  expandedKeysValue: [],
  keywords: null,
  major: '',
  referStep: 1,
  step: 1,
  title: null,
  titleData: [],
  treeValue: [],
  wenxian: [],
  xuanti: null,
  zhuanye: null,
  thesisType: 0, // 0: 课程论文 1:本科论文 2: 专科论文  5: 降重 6: 降AIGC
  lunwenzishu: 600,
  lowStep: 1
}
export const defaultOpenReportData = {
  title: null,
  treeValue: null,
  zhuanye: null,
  xuanti: null,
  expandedKeysValue: [],
  step: 1,
  titleData: [],
  ansewrDate: '',
  keywords: null,
  referStep: 1,
  showPlanRight: false,
  stageData: [
    {
      checked: false,
      title: '文献回顾和研究预备阶段',
      startTime: '',
      endTime: '',
      disabled: true,
      placeholder: '待生成',
      day: '',
      ratio: 3
    },
    {
      checked: false,
      title: '数据收集和处理阶段',
      startTime: '',
      endTime: '',
      disabled: true,
      placeholder: '待生成',
      day: '',
      ratio: 2
    },
    {
      checked: false,
      title: '分析和解释阶段',
      startTime: '',
      endTime: '',
      disabled: true,
      placeholder: '待生成',
      day: '',
      ratio: 1
    },
    {
      checked: false,
      title: '撰写论文阶段',
      startTime: '',
      endTime: '',
      disabled: true,
      placeholder: '待生成',
      day: '',
      ratio: 2
    },
    {
      checked: false,
      title: '论文审阅和修改阶段',
      startTime: '',
      endTime: '',
      disabled: true,
      placeholder: '待生成',
      day: '',
      ratio: 2
    },
    {
      checked: false,
      title: '论文提交和答辩准备阶段',
      startTime: '',
      endTime: '',
      disabled: true,
      placeholder: '待生成',
      day: '',
      ratio: 2
    }
  ]
}
export const defaultCreatPPTData = {
  creatPPTStep: '1-1', // 1-1-1 解释 1：步骤（1:生成大纲，选择主题，生成PPT） 1：sub步骤 1：生成大纲类型（1:AI生成，2:论文原稿，3:AI unipaper）
  selectTopic: '',
  outLine: null,
  file: null,
  theme: null,
  historyId: null,
  resultPage: { pages: [], thumbnail: '' },
  selectPageInfo: {},
  selectRightIndex: null,
  rightInfo: [],
  isSure: false,
  downfile: null,
  filetime: null,
  isUpdateTheme: false
}
export const initData = {
  clientWidth: 0,
  userInfo: {
    sum_words: 0,
    expend_words: 0
  },
  modelTab: 1,
  ...defaultData,
  ...defaultCreatPPTData,
  ...defaultOpenReportData
}

export const appReducer = (state: any, action: any) => {
  switch (action.type) {
    case CHAT_DEFAULT:
      return {
        ...state,
        ...action.payload
      }
    default:
      return state
  }
}
