import { useEffect, useRef } from 'react'
import './Thinking.less'

const Thinking = (props: any) => {
  const dom = useRef<any>(null)
  useEffect(() => {
    dom.current.scrollTo({ top: dom.current.scrollHeight - dom.current.clientHeight, behavior: 'smooth' })
  }, [props.thinkContent])
  return (
    <div className='Thinking-containter'>
      <div className='think-title'>
        <span>DeepSeek 思考中：</span>
        <span>（思考过程不计字数）</span>
      </div>
      <div className='think-content' ref={dom}>
        {props.children}
      </div>
    </div>
  )
}

export default Thinking
