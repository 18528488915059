import { useState, useRef } from 'react'
import './Accordion.less'

// eslint-disable-next-line react/prop-types
const HorizontalAccordion = ({ datas = [] as any, trigger = 'hover' }) => {
  const [activeIndex, setActiveIndex] = useState(4)
  const timer = useRef<any>(null)
  const handleHover = (index: any) => {
    if (trigger === 'hover') {
      if (timer.current) clearTimeout(timer.current)
      timer.current = setTimeout(() => {
        setActiveIndex(index)
      }, 500)
    }
  }

  // const handleLeave = () => {
  //   if (trigger === 'hover') {
  //     if (timer.current) clearTimeout(timer.current)
  //     setTimeout(() => {
  //       setActiveIndex(null)
  //     }, 500)
  //   }
  // }

  const handleClick = (index: any) => {
    if (trigger === 'click') {
      setActiveIndex(activeIndex === index ? null : index)
    }
  }

  return (
    <div className='horizontal-accordion'>
      {datas.map((item: any, index: number) => (
        <div
          key={index}
          className={`accordion-panel ${activeIndex === index ? 'active' : ''}`}
          onMouseEnter={() => handleHover(index)}
          // onMouseLeave={handleLeave}
          onClick={() => handleClick(index)}
        >
          <div className='accordion-icon'>{item.icon}</div>
          {/* {activeIndex === index && ( */}
          <div className={`accordion-details accordion-details${index}`}>
            <div className='accordion-title'>{item.title}</div>
            <div className='accordion-content'>{item.content}</div>
          </div>
          {/* )} */}
        </div>
      ))}
    </div>
  )
}

export default HorizontalAccordion
