import { memo, useState } from 'react'
import Thinking from '@/components/Thinking/Thinking'
import './Thinks.less'
// const unityUrl = process.env.VITE_NAME
declare const window: any
const Thinks = () => {
  const [thinkContent, setThinkContent] = useState()
  window.setThinkContent = setThinkContent
  return (
    <>
      {thinkContent ? (
        <div className='thinks-container'>{thinkContent && <Thinking thinkContent={thinkContent}>{thinkContent}</Thinking>}</div>
      ) : null}
    </>
  )
}
export default memo(Thinks)

// import { memo, useState, useEffect, useRef } from 'react'
// // import Thinking from '@/components/Thinking/Thinking'
// import './Thinks.less'
// // const unityUrl = process.env.VITE_NAME
// declare const window: any
// const Thinks = () => {
//   const [thinkContent, setThinkContent] = useState()
//   const dom = useRef<any>(null)
//   useEffect(() => {
//     dom.current.scrollTo({ top: dom.current.scrollHeight - dom.current.clientHeight, behavior: 'smooth' })
//   }, [thinkContent])
//   window.setThinkContent = setThinkContent
//   return (
//     <>
//       <div className='Thinking-containter'>
//         <div className='think-title'>
//           <span>DeepSeek 思考中：</span>
//           <span>（思考过程不计字数）</span>
//         </div>
//         <div className='think-content' ref={dom}>
//           {thinkContent}
//         </div>
//       </div>
//     </>
//   )
// }
// export default memo(Thinks)
