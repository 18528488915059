import { memo, useState, useContext, useRef } from 'react'
import { withRouter } from 'react-router-dom'
import { ThemeContext, CHAT_DEFAULT } from '@/store/store'
import ActivityModal from '@/components/ActivityModal/ActivityModal'
import BuyModal from '@/components/BuyModal/BuyModal'
import UserModal from '@/components/UserModal/UserModal'
import PlatformModal from '@/components/PlatformModal/PlatformModal'
import LunwenModal from '@/components/LunwenModal/LunwenModal'
import JoinGroup from '@/components/JoinGroup/JoinGroup'
import { model } from '@/utils/global-const'
// import { getUserInfoApi } from '@/server/commonFetch'
import { Progress, Button, Popover, message } from 'antd'
import './index.less'
const unityUrl = process.env.VITE_NAME
const dealData = [{ icon: 'icon-chongzhi' }, { icon: 'icon-icon-user' }, { icon: 'icon-icon-wenhao' }, { icon: 'icon-lunwen' }]
const Title = (props: any) => {
  const { userInfo = {}, modelTab = 1, dispatch } = useContext<any>(ThemeContext)
  const [logo, setLogo] = useState('AI UniPaper')
  const [eyeIcon, setEyeIcon] = useState(false)
  const [buyVisible, setBuyVisible] = useState(false)
  const [userVisible, setUserVisible] = useState(false)
  const [platVisible, setPlatVisible] = useState(false)
  const [lunwenVisible, setLunwenrVisible] = useState(false)
  const [joinGroupVisible, setJoinGroupVisible] = useState(false)
  const [activityModal, setActivityModal] = useState(false)
  const [closeClass, setCloseClass] = useState(true)
  const words = useRef<any>(null)
  const showModelBtn = [unityUrl + '/openReportbody', unityUrl + '/mainbody'].includes(props.location.pathname)
  const onMouseover = () => {
    setLogo('Home')
  }
  const onMouseout = () => {
    setLogo('AI UniPaper')
  }
  const iconClick = (index: number) => {
    if (index === 0) {
      setBuyVisible(true)
      return
    }
    if (index === 1) {
      setUserVisible(true)
      return
    }
    if (index === 2) {
      setPlatVisible(true)
      return
    }
    if (index === 3) {
      setLunwenrVisible(true)
      return
    }
  }
  return (
    <>
      <nav className='title-container'>
        <span
          className='logo'
          onMouseOver={onMouseover}
          onMouseOut={onMouseout}
          onClick={() => {
            props.history.push(unityUrl + '/')
          }}
        >
          {logo}
        </span>
        {eyeIcon ? null : (
          <>
            <Progress
              percent={
                userInfo.sum_words - userInfo.expend_words <= 0
                  ? 100
                  : Math.floor((userInfo.expend_words / userInfo.sum_words) * 100)
              }
              strokeColor='#000000'
            />
            <Popover placement='top' content={`已消耗字数${userInfo.expend_words} / 充值字数${userInfo.sum_words}`}>
              <span className='words'>
                <label>{userInfo.expend_words}</label> / <label>{userInfo.sum_words}</label>
              </span>
            </Popover>
            {userInfo.sum_words === 0 ? (
              <Button
                className='free-btn'
                type='primary'
                onClick={() => {
                  setJoinGroupVisible(true)
                }}
              >
                免费领取字数
              </Button>
            ) : null}
            {showModelBtn ? (
              <div className='model-tab'>
                {[
                  { icon: 'icon-deepseek', label: 'DeepSeek' },
                  { icon: 'icon-chatgpt', label: 'GPT-' + model }
                ].map((item: any, index: number) => {
                  return (
                    <div
                      className={`bnt-box bnt-box${index} ${index == modelTab ? 'bnt-box-active' : ''}`}
                      key={index}
                      onClick={() => {
                        if (modelTab === index) return
                        dispatch({ type: CHAT_DEFAULT, payload: { modelTab: index } })
                        message.success({
                          content: '正文AI模型已切换为 ' + item.label,
                          icon: (
                            <i
                              className='icon-duigougouxuan_xuanzhong icon iconfont'
                              style={{ marginRight: '0.08rem', fontSize: '.2rem', transform: 'translateY(1rem)' }}
                            />
                          )
                        })
                      }}
                    >
                      <i className={`icon iconfont ${item.icon}`}></i>
                      <span>{item.label}</span>
                    </div>
                  )
                })}
              </div>
            ) : null}
            <ul>
              {dealData.map((item: any, index: number) => {
                return (
                  <Popover key={index} placement='top' content={['充值中心', '用户信息', '使用说明', '论文管理'][index]}>
                    <li key={index}>
                      <i className={`icon iconfont ${item.icon}`} onClick={() => iconClick(index)} />
                    </li>
                  </Popover>
                )
              })}
            </ul>
          </>
        )}
        <Popover placement='top' content={'显示/隐藏'}>
          <i
            className={`icon iconfont dealicon ${eyeIcon ? 'icon-yanjing_xianshi_o' : 'icon-buxianshi1'}`}
            onClick={() => setEyeIcon(!eyeIcon)}
          />
        </Popover>
      </nav>
      {buyVisible && (
        <BuyModal
          visible={buyVisible}
          words={words.current}
          onCancel={() => {
            words.current = null
            setBuyVisible(false)
          }}
          onActivityModal={() => {
            setActivityModal(true)
            setCloseClass(true)
            setBuyVisible(false)
          }}
        />
      )}
      {userVisible && (
        <UserModal
          visible={userVisible}
          onCancel={() => {
            setUserVisible(false)
          }}
        />
      )}
      {platVisible && (
        <PlatformModal
          visible={platVisible}
          onCancel={() => {
            setPlatVisible(false)
          }}
        />
      )}
      {lunwenVisible && (
        <LunwenModal
          visible={lunwenVisible}
          onCancel={() => {
            setLunwenrVisible(false)
          }}
        />
      )}
      {joinGroupVisible && (
        <JoinGroup
          visible={joinGroupVisible}
          onCancel={() => {
            setJoinGroupVisible(false)
          }}
        />
      )}
      <ActivityModal
        visible={activityModal}
        closeClass={closeClass}
        onCancel={() => {
          setCloseClass(false)
          setTimeout(() => {
            setActivityModal(false)
          }, 400)
        }}
        buy={(d: any) => {
          words.current = d
          setBuyVisible(true)
        }}
      />
    </>
  )
}

export default memo(withRouter(Title))
